var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu container"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"menu__title"}),_c('div',{staticClass:"flex justify-between items-center"},[_c('filters',{attrs:{"name":"Store","hasSelectAll":false,"items":_vm.filters.stores,"storageIsFull":_vm.storageIsFull,"isMainPage":_vm.isMainPage,"checkedStores":_vm.checkedStores.length},on:{"update":_vm.getStoresFilters}}),_c('filters',{attrs:{"name":"Category","items":_vm.filters.categories,"tree":""},on:{"update":_vm.getCategoriesFilters}}),_c('filters',{class:{'disabled': !_vm.isCategoriesChecked || !_vm.filters.brands.values.length },attrs:{"name":"Brand","items":_vm.filters.brands},on:{"update":function($event){return _vm.updateState('brands')}}}),_c('filters',{attrs:{"name":"Location","items":_vm.filters.regions}}),_c('div',{staticClass:"accuracy__date flex items-center ml-20"},[_c('DatePicker',{attrs:{"is-range":"","max-date":_vm.lastDay,"min-date":_vm.startingDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
var togglePopover = ref.togglePopover;
return [_c('div',{staticClass:"date",on:{"click":togglePopover}},[_c('calendar-blank',{staticClass:"mr-4",attrs:{"size":18}}),_c('span',[_vm._v(_vm._s(_vm.formattedDateFrom))]),(_vm.isRange)?_c('span',[_vm._v("- "+_vm._s(_vm.formattedDateTo))]):_vm._e()],1)]}}]),model:{value:(_vm.rangeDates),callback:function ($$v) {_vm.rangeDates=$$v},expression:"rangeDates"}}),(_vm.isMainPage)?_c('DatePicker',{attrs:{"is-range":"","max-date":_vm.lastDay,"min-date":_vm.startingDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
var togglePopover = ref.togglePopover;
return [_c('div',{staticClass:"date",on:{"click":togglePopover}},[(!_vm.rangeDatesSecond.start)?_c('span',[_vm._v("Сравнить")]):[_c('calendar-blank',{staticClass:"mr-4",attrs:{"size":18}}),_c('span',[_vm._v(_vm._s(_vm.formattedSecondDateFrom))]),(_vm.isRangeSecond)?_c('span',[_vm._v("- "+_vm._s(_vm.formattedSecondDateTo))]):_vm._e()]],2)]}}],null,false,1264539668),model:{value:(_vm.rangeDatesSecond),callback:function ($$v) {_vm.rangeDatesSecond=$$v},expression:"rangeDatesSecond"}}):_vm._e()],1),_c('button',{staticClass:"apply",on:{"click":_vm.clearFilters}},[_vm._v("Clear")]),_c('button',{staticClass:"apply",attrs:{"disabled":!_vm.isCheckedFull},on:{"click":_vm.updateResults}},[_vm._v("Apply filter")]),_c('span',{staticClass:"separator"}),_c('div',{staticClass:"flex justify-between items-center"},[_c('Export',{staticClass:"dashboard__export",attrs:{"type":"white"},on:{"click":_vm.exportHandler}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }